.webdiv {
  position: relative;
  display: flex;
  
  width: 100%;
  overflow: hidden;
  
  margin-bottom: 16rem;
}
.webdiv:before,
.webdiv:after {
  position: absolute;
  top: 0;
  width: 200px;
  height: 100%;
  content: "";
  z-index: 2;
}

.webdiv:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), black);
}

.webdiv:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), black);
}
.webdiv .otherweb-div {
  animation: slideIn var(--t) linear infinite;
  animation-delay: calc(var(--t) * -1);

  display: flex;

  justify-content: center;
  align-items: center;
}
.webdiv .otherweb-div:nth-child(2) {
  animation: slideIn-2 var(--t) linear infinite;
  animation-delay: calc(var(--t) / -2);
}

.webdiv .otherweb-div .otherWeb-card {
  width: 450px;
  height: 250px;
  padding: 15px;
  border-radius: 1rem;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  color: goldenrod;
  margin: 0 10px 0 10px;
}
.webdiv:hover .otherweb-div {
  .otherWeb-card:hover {
    .visit-btn {
      cursor: pointer;
      background-color: black;
      color: white;
      transform: scale(1.03);
      transition: 0.3s ease-in;
    }
  
    background-color: white;
    color: black;
    border: 2px solid black;
    transition: 0.2s ease-in;
  }
  animation-play-state: paused;
}


.otherWeb-card a button {
  padding: 15px 50px;
  font-size: 18px;

  letter-spacing: 1.5px;
  background-color: white;
  color: black;
  border-radius: 1.1rem;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes slideIn-2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}

@media screen and (max-width: 767px) {
.webdiv:before,
.webdiv:after {
  
  width: 30px;
  
}
.webdiv .otherweb-div .otherWeb-card {
  width: 400px;
  height: 250px;
  padding: 10px;
 
}
.webdiv .otherweb-div .otherWeb-card h2{
  font-size: 20px;
}
  
}