.topic-section-main {
  width: 100%;
  margin: 0;
  margin-bottom: 7rem;
  padding: 20px;
  background: linear-gradient(to right, #000000, #043b53, #000000);
}

.back-nav-btn {
  background: none;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
}

.back-nav-btn p {
  color: #40c9ff;
  font-size: 1.5rem;
}

.back-arrow {
  width: 55px;
}

.back-arrow:hover {
  transition: 0.1s ease-in;
  transform: scale(1.19);
}

.topics {
  width: 100%;

  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 1rem;
}
.topics-centre-line {
  width: 100%;
  margin: 2rem auto;
  border: 1px solid white;
}
.topics-second-div {
  width: 100%;
  margin: 1rem auto;
  gap: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  flex-wrap: wrap;
}
.topics-third-div {
  margin: auto;
  border: 2px solid;
  border-image: linear-gradient(320deg, transparent 0%, #e81cff, #40c9ff);
  border-image-slice: 1;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.h1-1 {
  color: goldenrod;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  font-family: "Fjalla One", sans-serif;
  letter-spacing: 1.5px;
  padding: 10px;
  margin-top: 1rem;
}
.h1-2 {
  color: goldenrod;
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  font-family: "Fjalla One", sans-serif;
  letter-spacing: 1.5px;
  padding: 10px;
  margin-top: 1rem;
}
.basic-topics {
  width: 90%;

  overflow: auto;
  height: 60vh;
  padding: 2rem;
  margin-top: 30px;
  text-align: center;
  column-gap: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid;
  border-image: linear-gradient(320deg, transparent 0%, #e81cff, #40c9ff);
  border-image-slice: 1;

  border-radius: 1rem;
  scrollbar-width: thin;

  flex-wrap: wrap;
}

.advance-topics {
  width: 40vw;
  margin: auto;
  padding: 1rem 2rem;
  margin-top: 30px;
  text-align: center;
  column-gap: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  flex-wrap: wrap;
}
.basic-topics li,
.advance-topics li {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(236, 231, 188);

  font-size: 19px;
  margin-bottom: 1rem;

  padding: 10px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  border-radius: 1rem;
  text-align: center;
  transition: transform 0.2s ease;
}
.topics ul li:hover {
  transform: scale(1.02);
}

.topic-link-2 {
  text-decoration: none;
  margin: 1rem;
}
.topic-button {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  /* padding-block: 1rem;
    padding-inline: 2rem; */
  padding: 1rem 2rem;
  background-color: black;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  gap: 10px;
  font-weight: bold;
  border: 3px solid #ffffff4d;
  outline: none;
  overflow: hidden;
  font-size: 18px;
}

.topic-button:hover {
  transform: scale(1.05);
  border-color: #fff9;
}

.topic-button:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.topic-button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}

@media screen and (max-width: 767px) {
  /* .topic-section-main {
    margin-bottom: 100rem;
  } */

  .back-nav-btn p {
    font-size: 1.1rem;
  }

  .back-arrow {
    width: 30px;
  }

  .back-arrow:hover {
    transition: 0.1s ease-in;
    transform: scale(1.19);
  }

  .topic-section-main {
    width: 100vw;
    margin: 0;
    background: linear-gradient(to right, #000000, #043b53, #000000);
  }
  .topics {
    width: 100vw;

    border-radius: 1rem;
  }

  .topics-second-div {
    width: 100%;
    margin: 1rem auto;
    gap: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    flex-wrap: wrap;
  }
  .topics-third-div {
    width: 100vw;
    margin: auto;
    border: 2px solid;
    border-image: linear-gradient(320deg, transparent 0%, #e81cff, #40c9ff);
    border-image-slice: 1;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .h1-1 {
    color: goldenrod;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    font-family: "Fjalla One", sans-serif;
    letter-spacing: 1.5px;
    padding: 10px;
    margin-top: 1rem;
  }
  .h1-2 {
    color: goldenrod;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    font-family: "Fjalla One", sans-serif;
    letter-spacing: 1.5px;
    padding: 10px;
    margin-top: 1rem;
  }
  .basic-topics {
    width: 100%;

    overflow: auto;
    height: 60vh;
    padding: 2rem;
    margin-top: 30px;
    text-align: center;
    column-gap: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    border: 2px solid;
    border-image: linear-gradient(320deg, transparent 0%, #e81cff, #40c9ff);
    border-image-slice: 1;

    border-radius: 1rem;
    scrollbar-width: thin;

    flex-wrap: wrap;
  }

  .advance-topics {
    width: 100%;
    margin: auto;
    padding: 1rem 2rem;
    margin-top: 30px;
    text-align: center;
    column-gap: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    flex-wrap: wrap;
  }
  .basic-topics li,
  .advance-topics li {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(236, 231, 188);

    font-size: 19px;
    margin-bottom: 1rem;

    padding: 10px 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    border-radius: 1rem;
    text-align: center;
    transition: transform 0.2s ease;
  }
  .topics ul li:hover {
    transform: scale(1.02);
  }
}
