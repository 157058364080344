.com-logo-sec{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 70px 0 40px 0;
  background: radial-gradient(  #1a0122, #000000);

 
}


.course-main-div {
  position: relative;
  margin: auto;
  padding:20px 40px;
  width: 100%;
  margin-bottom: 120px;
  transition: transform 0.5s ease;
}
.courese-inner-div {
  display: flex;
  justify-content:space-around;
  align-items:center;
  flex-wrap: wrap;
  width: 100%;
 
}

.tick-sym {
  border-radius: 50%;
  margin-right: 10px;
}

.left-div,
.right-div {

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  border-radius: 8px;
  border: 2px solid transparent;
  background: linear-gradient(black, black) padding-box,linear-gradient(55deg, transparent 35%, #e81cff, #40c9ff) border-box;
}
.right-div {
  background: linear-gradient(black, black) padding-box,linear-gradient(240deg, transparent 35%, #e81cff, #40c9ff) border-box;
}

/* .center-line {
 
  border: 0.1px solid gray ;
  margin:20px  auto;
} */

.left-title,
.right-title {
  color:rgb(241, 211, 136);
  font-size: 30px;
  font-weight: bold;

  margin-bottom: 10px;

}

.left-div ul,
.right-div ul {
  list-style-type: none;
  padding: 0;
  
 
}

.left-div li,
.right-div li {
  color:whitesmoke;
  font-size: 18px;
  padding: 15px 0;
  text-align: left;
  display: flex;
  justify-content: left;
  align-items: left;
  
  font-family: "Dosis", sans-serif;
  font-weight: 600;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  width: 100%;
  overflow: hidden;
  padding: 0;
  /* column-gap: 10rem; */
  /* background-color: rgb(255, 251, 243); */
  /* background-color: white; */
  white-space: nowrap;
  position: relative;
 
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), rgb(6, 6, 6));
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(0, 0, 0));
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-block;
  
  animation: 15s slide infinite linear;
}

.logos-slide img {
  height: 150px;
  margin: 0 70px;
  
  
}

.img2-logo {
  border-radius: 3rem;
}
@media screen and (max-width: 767px) {
  .course-main-div {
  
    padding:20px;
    
  }
  .left-div,
.right-div {

 
  padding: 20px 10px;
}
.left-title,
.right-title {
  font-size: 21px;
 
}
.left-div li,
.right-div li {

  font-size: 16px;
  padding: 15px 0;
  
}


}