.sec-1-div {
  /* background-color: #1f1e1e; */
  background: linear-gradient(to bottom, #2b2a2a, #000000);

  text-align: center;
  padding: 20px;
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
}

.softwares-name {
  color: rgb(0, 255, 247);
  font-weight: bold;
  font-size: 1.6em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.logo-img {
  width: 30%;
  max-width: 100%;
  display: flex;

  justify-content: space-evenly;
  align-items: center;
}

.logo-img img {
  max-width: 100%;
  width: 100px;
  height: auto;

  object-fit: cover;
}
.title-div {
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.company-name {
  font-size: 48px;
  font-weight: 300;
  letter-spacing: 2px;
  color: white;
}
.company-quote {
  font-size: 23px;
  color: rgb(227, 198, 120);
}

.quotes {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.quotes-2 {
  /* position: relative; */
  color: white;
  font-family: "Fjalla One", sans-serif;
  font-size: 3em;
  font-weight: 900;
  margin-bottom: 8px;
  letter-spacing: 2px;
  width: 80%;
  background: linear-gradient(to right, #2d85c0, #b8b8b8, #973670);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.motto-quote {
  color: rgb(247, 222, 175);
  font-size: 1.5em;
  font-family: "Dosis", sans-serif;
  font-weight: 600;
  margin-top: 20px;
}

.offer-end-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.offer-end-main h3 {
  margin-bottom: 10px;
  letter-spacing: 2px;
  font-size: 1.2em;
  color: white;
}

.offer-end-div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.time-box {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  border-radius: 8px;
  text-align: center;

  background: linear-gradient(black, black) padding-box,
    linear-gradient(145deg, transparent 35%, #e81cff, #40c9ff) border-box;
  padding: 10px;
  transform-origin: right bottom;
  border: 2px solid transparent;
}

.min,
.sec {
  color: #2d85c0;
  font-size: 1.5em;
}
.minutes,
.seconds {
  color: rgb(247, 222, 175);
  font-size: 3em;
  font-weight: 400;
}
.offer-timer-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-direction: column;
}
.offer-button {
  text-decoration: none;
  display: inline-block;
  margin: 1rem 0;
}

.offer-button button {
  padding: 0.8em 1.8em;
  border: 2px solid #17c3b2;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  transition: 0.3s;
  z-index: 1;
  font-family: inherit;
  color: #17c3b2;
}

.offer-button button::before {
  content: "";
  width: 0;
  height: 600%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #17c3b2;
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}

.offer-button button:hover::before {
  width: 200%;
}

.offer-button button:hover {
  color: #111;
}

@keyframes anime {
  0% {
    box-shadow: 0 0 10px rgba(255, 165, 0, 0.8);
  }
  50% {
    box-shadow: 0 0 40px rgba(255, 165, 0, 0.8);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 165, 0, 0.8);
  }
}
.counter-timer {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.counter-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
.numberCardview {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.numberCardview p {
  font-size: 28px;
  font-weight: 400;
  color: rgb(255, 255, 255);
}

.numberCardview h1 {
  font-size: 40px;
  color: bisque;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.numberCardview {
  transition: transform 0.3s ease-in-out;
}

.numberCardview:hover {
  transform: scale(1.05);
}
/* @media screen and (max-width: 720px) {
 
  .counter-div {
    flex-direction: column;
    padding: 0 10px;
  }
} */

@media screen and (max-width: 768px) {
  .softwares-name {
    font-size: 0.9em;
    font-weight: 600;
    
  }

  .sec-1-div {
    padding: 10px;
    justify-content: space-evenly;
  }
  .logo-img {
    margin-left: 0px;
    width: fit-content;
    flex-direction: column;
  }

  .quotes {
    width: 100%;
  }
  .company-name {
    font-size: 30px;
    letter-spacing: 3px;
    margin-top: 5px;
  }
  .company-quote {
    font-size: 18px;
  }
  .quotes-center {
    position: absolute;
    left: 50%;
    top: -1.9%;
    text-align: center;
    transform: translateX(-50%);

    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    background-color: crimson;
    padding: 0.5rem;
    border-radius: 4rem;
  }
  .quotes-2 {
    margin: 0.5rem auto;
    letter-spacing: 1px;
    font-size: 1.5em;
    width: 100%;
  }

  .typeanimation {
    font-size: 1.4em !important;
  }
  .motto-quote {
    font-size: 0.95em;
    margin-top: 10px;
  }

  .course-main-div {
    width: 95%;
    padding: 3rem 0.5rem 2rem 2rem;
  }
  .courese-inner-div {
    flex-direction: column;
    justify-content: center;
    align-items: left;
  }

  .left-div,
  .right-div {
    flex: 1;
    padding: 0;
  }

  .tick-sym {
    margin-right: 10px;
  }

  .center-line {
    width: 45%;
    border-bottom: 1px solid white;
    margin: 15px auto;
  }

  .offer-timer-div {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }
  .time-box {
    font-size: 10px;

    padding: 5px 20px;
  }
  .offer-button {
    margin: 0 0 0.5rem 0;
  }

  .offer-button button {
    padding: 10px 20px;
  }
  .counter-timer {
    justify-content: center;
    gap: 20px;
  }
  .counter-div {
    width: 100vw;
    padding: 10px 0;
  }
  .numberCardview {
    width: 100%;
    padding: 0 10px;
  }
  .numberCardview p {
    font-size: 14px;
    font-weight: 400;
  }

  .numberCardview h1 {
    font-size: 20px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .offer-timer-div {
    width: 100%;
    padding: 0 10px;
    flex-direction: column;
  }
}
