.sec-3 {
  padding: 40px;
  /* background: linear-gradient(to right, #022c48, #00e1ff, #000000); */
  background: linear-gradient(to right, #000000, #01051f);

  display: flex;
  flex-wrap: wrap;
  justify-content:flex-start;
  align-items:flex-start;
}

.feature-list {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: auto;
  padding: 1rem;
  /* background-color: white; */
  color: white;

  letter-spacing: 1px;
}
.features-title {
  color: rgb(221, 205, 140);
  font-size: 35px;
  font-weight: bolder;
  font-family: "Fjalla One", sans-serif;
  letter-spacing: 1.5px;
  text-align: center;
  padding: 20px 10px;
}
.sec-3-center-line {
  width: 90px;
  border-bottom: 0.1px solid rgb(129, 106, 57);
  margin: auto;
  margin-bottom: 40px;
}

.sec-3 ul {
  list-style-type: none;
  padding: 0;
}

.sec-3 li {
  margin-bottom: 5px;
  padding: 5px;
  display: flex;
  font-family: "Dosis", sans-serif;
  font-size: 20px;
  font-weight: bold;
}



.course-pdf {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  background-color: #3498db;
  color: #ffffff;
  border-radius: 5px;
  border: 2px solid #3498db;
  cursor: pointer;
  transition: transform 0.5s ease, letter-spacing 0.5s ease;
}

.course-pdf:hover {
  background-color: #2980b9;
  border-color: #2980b9;
  color: #ffffff;
  transform: translateY(10%);
  letter-spacing: 1px;
}

.brochureImg-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 550px;
  margin: auto;
  background: linear-gradient(black, black) padding-box,
  linear-gradient(320deg, transparent 0%, #e81cff, #40c9ff) border-box;
border: 2px solid transparent;
border-radius: 1rem;
}
.brochure-img {
  max-width: 100%;
  height: auto;
  border-radius: 1rem;
  padding: 5px;
}
.pricing-btn {
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sec-4 {
  /* background: linear-gradient(to right, #000000, #252323, #000000); */

  padding: 40px 10px;
  margin: auto;
}

.training-info-div {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.training-heading {
  color: rgb(221, 205, 140);
  font-size: 35px;
  font-weight: 700;
  font-family: "Fjalla One", sans-serif;
  letter-spacing: 1.5px;
}

.ul-div {
  width: 100%;

  padding: 20px;
  overflow: -moz-hidden-unscrollable;

  background: linear-gradient(black, black) padding-box,
    linear-gradient(320deg, transparent 0%, #e81cff, #40c9ff) border-box;
  border: 2px solid transparent;
  border-radius: 1rem;
  margin-top: 30px;
}
.training-targets {
  width: 100%;
  height: 60vh;
  padding: 20px;
  overflow: auto;
}

.training-targets li {
  width: 16rem;
  color: white;
  font-size: 19px;
  margin: 1rem auto;

  /* background-color: maroon; */
  padding: 10px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
  background: linear-gradient(black, black) padding-box,
    linear-gradient(0deg, transparent 0%, #cac4c4, #ffffff) border-box;
  border-radius: 1rem;
  text-align: center;
  transition: transform 0.2s ease;
  border: 2px solid transparent;
}

.training-targets li:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 900px) {
  .sec-3 {
    padding: 40px 10px;
  }
  .feature-list {
    width: 100%;
    padding: 0px;
  }
  .features-title {
    color: rgb(221, 205, 140);
    font-size: 28px;
    text-wrap: nowrap;

    letter-spacing: 1.5px;
    text-align: center;
    padding: 20px;
  }
  .sec-3 li {
    margin-bottom: 5px;
    padding: 5px;
    display: flex;
    font-family: "Dosis", sans-serif;
    font-size: 18px;
    font-weight: bold;
  }

  .more-content-div {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
    padding: 0;
  }
  .brochureImg-div {
    width: 100%;
  }
  .more-content1 {
    font-size: 28px;

    margin-top: 0;

    padding: 20px 0;
  }
  .topics {
    width: 100%;
  }
  .topics h1 {
    font-size: 18px;
    padding: 2rem 0.5rem 1rem 0.5rem;
    margin: 0;
  }
  /* .topics ul li {
    width: 100%;
  } */

  .sec-4 {
    padding: 60px 0;
    margin: auto;
  }

  .training-info-div {
    max-width: 100%;
  }

  .training-heading {
    font-size: 20px;
  }

  .training-targets li {
    font-size: 17px;
  }
}
