* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}
section {
  
  min-height: 50vh;
  overflow: hidden;
  
}


