.sec-7 {
  padding: 70px 40px 10px 40px;
  width: 100%;
  /* background-color: maroon; */
  background: radial-gradient( #021230, #000000);

}

.video-review-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color:maroon; */

  padding: 1rem;
}

.video-review-container h1 {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  font-family: "Fjalla One", sans-serif;
  letter-spacing: 1.5px;
  margin-bottom: 40px;
  color:white;
}

.video-main-div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
 
  
  
}

.video-review-card {
  /* border: 2px solid black; */
  padding: 15px;
  background: linear-gradient(black, black) padding-box,
  linear-gradient(320deg, transparent 0%, #e81cff, #40c9ff) border-box;

border: 2px solid transparent;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.5s ease;
  transition: transform 0.5s ease;
}

.video-review-card:hover {
  /* box-shadow: 0 0 20px 10px rgba(255, 255, 255,0.5); */
  transform: scale(1.03);
}

.video-review-card .reviewer-name {
  font-weight: bold;
  color:white;
}

.reviewer-video {
  margin-top: 10px;
}
.reviewer-video iframe{
  
  border-radius: 0.5rem;
}
.reviewer-experience {
  margin-top: 10px;
  color: #aca5a5;
  text-align: center;
}

@media screen and (min-width:768px) and (max-width:1100px) {
  .sec-7 {
    padding: 70px 30px;
  }
  .video-review-container h1 {
    font-size: 24px;
  }
  .video-main-div {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    
  }
  .video-review-card:nth-child(odd):last-child {
    grid-column: span 2;
    width: 50%;
  }
  .video-review-card {
    text-align: center;
    width: 100%;
    margin:0 auto;
   
    
  }
}
@media screen and (max-width:768px){

  .sec-7 {
    padding:40px 10px 0 10px;
  }
  .video-review-container h1 {
    font-size: 24px;
  }
  .video-main-div {
    grid-template-columns: 1fr;
  }

  .video-review-card {
    text-align: center;
    width: 95%;
    margin: auto;
   
    
  }

}
