.tab-section{
  width: 100%;
  min-height: 75vh;
  
  display: flex;
  padding: 1rem 1rem 3rem 1rem;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;

}
.tabs-container {
    display: flex;
    flex-direction: column;
    width: 75vw;
    
     /* Adjust as needed */
   
    margin-top: 3rem;
    
  }
  
  .tabs {
    display: flex;
    column-gap: 3rem;
  }
  
  .tab {
    font-size: 1.2em;
    font-weight: bold;
    color: black;
    flex: 1;
    padding: 12px;
    cursor: pointer;
    text-align: center;
    border: 2.3px dashed;
    border-radius: 25px;
    transition: border 0s ease, border-radius 0.4s ease;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .tab:hover {
    transition: 0.3s ease;
    border-radius: 12px;
  }
  
  .tab.active {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.1rem;
    border: none;
    background-color: black;
    color: white;
  }
  
  .content {
    color: black;
    margin-top: 2rem;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .content.active {
    opacity: 1;
    animation: fadeUp 0.5s ease forwards;
  }
  
  /* content styling */
  
  .pro-plan-column-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: auto;
    padding: 10px 20px;
    background-color: black;
    border: 1px solid #333;
    border-radius: 10px;
    width: 100%;
    min-height: 40vh;
    color: black;
    transition: background-color 0.5s, color 0.5s, transform 0.5s;
    box-sizing: border-box;
  }
  
  .plans-h1-2 {
    color: goldenrod;
    font-size: 28px;
    font-weight: 700;
    text-align: left;
    text-wrap: wrap;
  }
  
  .pro-plan-mode-2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 1rem;
  }
  .pro-plan-mode-2 b {
    display: flex;
    font-size: 1.3em;
    margin-top: 10px;
    color: red;
  }
  .pro-plan-mode-2 {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  
  .plans-p2-2 {
    font-size: 12px;
    text-align: left;
    margin-bottom: 15px;
    color: rgb(178, 176, 171);
  }
  .plans-p3-2 {
    color: white;
    text-align: left;
    margin-bottom: 15px;
  }
  
  .payment-center-line-2 {
    width: 100%;
    margin-left: 0;
    border-bottom: 0.1px solid #706f6f;
  }
  
  ul.basic-plan-features-2 {
    color: rgb(215, 196, 154);
    list-style: none;
    padding: 10px 0;
  }
  
  ul.basic-plan-features-2 li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    text-align: left;
  }
  
  ul.basic-plan-features-2 li img {
    margin-right: 10px;
  
    width: 15px;
    height: 15px;
  }
  
  /* navigation button style */
  
  .nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 1rem;
    background-color: white;
    color: black;
    border-radius: 10em;
    font-size: 17px;
    font-weight: 600;
    padding: 1em 2em;
    width: 30%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 1px solid black;
    box-shadow: 0 0 0 0 black;
  }
  
  .nav-btn:hover {
    transform: translateY(-4px) translateX(-2px);
    box-shadow: 2px 5px 0 0 black;
  }
  
  .nav-btn:active {
    transform: translateY(2px) translateX(1px);
    box-shadow: 0 0 0 0 black;
  }
  
  @keyframes fadeUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media screen and (max-width: 767px) {
    .tab-section{
      padding:1rem 0;
      min-height: 50vh;
    }
    .tabs-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100vw;
      padding: 0 0.5rem;
      
       /* Adjust as needed */
      margin: 1rem auto;
     
    }
    
    .tabs {
      overflow-x: auto;
      width: 100vw;
      display: flex;
      column-gap: 1rem;
      padding:0 0.5rem;
    }
    
    .tab {
      
      display: flex;
      justify-content: center;
      align-items: center;
      text-wrap: nowrap;
      font-size: 1.5em;
      font-weight: 700;
      color: black;
      
      padding:2px 10px;
      cursor: pointer;
      text-align: center;
      border: 2.3px dashed;
      border-radius: 1rem;
      transition: border 0s ease, border-radius 0.4s ease;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
    
    .tab:hover {
      transition: 0.3s ease;
      border-radius: 12px;
    }
    
    .tab.active {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size:1.5rem;
      border: none;
      background-color: black;
      color: white;
    }
    
    .content {
      color: black;
      margin-top: 2rem;
      border-radius: 10px;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    
    .content.active {
      opacity: 1;
      animation: fadeUp 0.5s ease forwards;
    }
    .pro-plan-column-2 {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin: auto;
      padding: 10px 20px;
      background-color: black;
      border: 1px solid #333;
      border-radius: 10px;
      width: 100%;
      min-height: 40vh;
      color: black;
      transition: background-color 0.5s, color 0.5s, transform 0.5s;
      box-sizing: border-box;
    }
    
    .plans-h1-2 {
      color: goldenrod;
      font-size: 28px;
      font-weight: 700;
      text-align: left;
      text-wrap: wrap;
    }
    
    .pro-plan-mode-2 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 1rem;
    }
    .pro-plan-mode-2 b {
      display: flex;
      font-size: 1.3em;
      margin-top: 10px;
      color: red;
    }
    .pro-plan-mode-2 {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 1rem;
    }
    
    .plans-p2-2 {
      font-size: 12px;
      text-align: left;
      margin-bottom: 15px;
      color: rgb(178, 176, 171);
    }
    .plans-p3-2 {
      color: white;
      text-align: left;
      margin-bottom: 15px;
    }
    
    .payment-center-line-2 {
      width: 100%;
      margin-left: 0;
      border-bottom: 0.1px solid #706f6f;
    }
    
    ul.basic-plan-features-2 {
      color: rgb(215, 196, 154);
      list-style: none;
      padding: 10px 0;
    }
    
    ul.basic-plan-features-2 li {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      text-align: left;
    }
    
    ul.basic-plan-features-2 li img {
      margin-right: 10px;
    
      width: 15px;
      height: 15px;
    }
    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      
      margin-top: 1rem;
      background-color: white;
      color: black;
      border-radius: 1rem;
      text-wrap: nowrap;
      font-size: 17px;
      font-weight: bold;
      padding: 1rem;
      width: fit-content;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      border: 1px solid black;
      box-shadow: 0 0 0 0 black;
    }
    
    .nav-btn:hover {
      transform: translateY(-4px) translateX(-2px);
      box-shadow: 2px 5px 0 0 black;
    }
    
    .nav-btn:active {
      transform: translateY(2px) translateX(1px);
      box-shadow: 0 0 0 0 black;
    }
    

  }